import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const AboutPage = ({ data }) => (
  <Layout pageHeaderText="About Me" pageHeaderImage="sound.png">
    <SEO title="About" />
    <h2 style={{textAlign:"center", fontSize:"3em", marginTop:"1rem", marginBottom:"2rem"}}>Who am I?</h2>
    <h4 style={{textAlign:"center", maxWidth:"960px", margin:"0 auto"}}>
        Well, I'm Ben.
    </h4>
    <p style={{textAlign:"center", maxWidth:"960px", margin:"0 auto", marginTop:"2rem"}}>
        I'm a 27 year old software engineer who loves to climb, cook, play video games with friends, and code.  I'm currently working on completing and releasing my first indie game - a casual mobile game called <Link to="/apps/blink">Blink</Link>.  I also have a <a href="https://youtube.com/c/devduck" target="_blank" rel="noopener noreferrer">YouTube channel</a> documenting that journey.
    </p>
    <p style={{textAlign:"center", maxWidth:"960px", margin:"0 auto", marginTop:"1rem"}}>
      Some day I'd love to independently develop software full time - until then, I'm happy making content that inspires others to make time in their busy lives for their passion projects.  Thanks for swinging by!
    </p>
    <Img style={{margin:"2rem 0"}} fluid={data.benImage.childImageSharp.fluid} alt="DevDuck AKA Ben"/>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    benImage: file(relativePath: { eq: "ben.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
